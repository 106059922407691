import { PlayerBundleId, AccountId, PlayerBundle__LinkedPlayers, PlayerId } from "@ollie-sports/models";
import { canMergePlayerBundles } from "../../compute/playerBundle.compute";
import * as express from "express";
import { BatchTask } from "@ollie-sports/firebase";
import { validateToken } from "../../internal-utils/server-auth";
import _ from "lodash";
import { updatedLinkedTeamsForPlayerBundleId } from "./helpers/updatedLinkedTeamsForPlayerBundleId";
import { getUniversalHelpers } from "../../helpers";

export async function playerBundle__server__mergePlayerBundles(p: {
  playerBundleId01: PlayerBundleId;
  playerBundleId02: PlayerBundleId;
  selfAccountId: AccountId;
  locale: string;
}): Promise<{ status: "success" } | { status: "failed"; reason: string }> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const pbs = await h.PlayerBundle.getDocs([p.playerBundleId01, p.playerBundleId02]);
  const bundle1 = pbs[0];
  const bundle2 = pbs[1];

  if (!bundle1 || !bundle2) {
    throw new Error("Unable to find playerBundle");
  }

  const canMerge = await canMergePlayerBundles({ pb1: bundle1, pb2: bundle2, locale: p.locale });

  if (canMerge.status === "allowed") {
    const tasks: BatchTask[] = [];

    // Mark the old playerBundle as deleted
    tasks.push(await h.PlayerBundle.update({ id: bundle2.id, doc: { deletedAtMS: Date.now() } }, { returnBatchTask: true }));

    // Fetch all players for both bundles
    let playersForBundle1 = (await h.Player.query({ where: [{ linkedPlayerBundleId: ["==", bundle1.id] }] })).docs;
    let playersForBundle2 = (await h.Player.query({ where: [{ linkedPlayerBundleId: ["==", bundle2.id] }] })).docs;

    // consolidate all player links to bundle1
    for (let i = 0; i < playersForBundle2.length; i++) {
      let bundle = playersForBundle2[i];
      tasks.push(await h.Player.update({ id: bundle.id, doc: { linkedPlayerBundleId: bundle1.id } }, { returnBatchTask: true }));
    }

    //Maybe delete a player or two from the team if merged players are on the same team
    const playersByTeam = [...playersForBundle1, ...playersForBundle2].reduce((acc, pl) => {
      acc[pl.teamId] = acc[pl.teamId] || [];
      acc[pl.teamId].push(pl.id);
      return acc;
    }, {} as Record<string, string[]>);

    const teamsWithDupes = Object.keys(playersByTeam).filter(teamId => playersByTeam[teamId].length > 0);

    let dupePlayerIds: PlayerId[] = [];

    const now = Date.now();
    for (let i = 0; i < teamsWithDupes.length; i++) {
      const allPlayerIds = playersByTeam[teamsWithDupes[i]];
      const playerIdToKeep = playersForBundle1.find(pl => allPlayerIds.find(pid => pid === pl.id))?.id || allPlayerIds[0];
      dupePlayerIds = allPlayerIds.filter(id => id !== playerIdToKeep);
      for (let j = 0; j < dupePlayerIds.length; j++) {
        const playerId = dupePlayerIds[j];
        tasks.push(
          await h.Player.setPath(
            { id: playerId, pathObj: { deletedAtMS: true }, value: { deletedAtMS: now } },
            { returnBatchTask: true }
          )
        );
      }
    }

    // Update derived on player bundles
    const linkedPlayers: PlayerBundle__LinkedPlayers = {};
    [...playersForBundle1, ...playersForBundle2].forEach(pl => {
      linkedPlayers[pl.id] = {
        status: pl.deletedAtMS === 0 && !dupePlayerIds.find(pid => pid == pl.id) ? "active" : "inactive",
        teamId: pl.teamId
      };
    });

    tasks.push(
      await h.PlayerBundle.setPath(
        {
          id: bundle1.id,
          pathObj: { derived: { linkedPlayers: true } },
          value: { derived: { linkedPlayers } }
        },
        { returnBatchTask: true }
      )
    );

    // Merge the accounts with an update
    tasks.push(
      await h.PlayerBundle.update(
        {
          id: bundle1.id,
          doc: { managingAccounts: bundle2.managingAccounts }
        },
        { returnBatchTask: true }
      )
    );

    await h._BatchRunner.executeBatch(tasks);

    await updatedLinkedTeamsForPlayerBundleId({ playerBundleId: bundle1.id });

    return {
      status: "success"
    };
  } else if (canMerge.status === "not_allowed") {
    return {
      status: "failed",
      reason: canMerge.reason
    };
  } else {
    throw new Error("Unknown can merge status");
  }
  // SERVER_ONLY_TOGGLE
}
playerBundle__server__mergePlayerBundles.auth = async (r: express.Request) => {
  validateToken(r);
};

// i18n certified - complete
