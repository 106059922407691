import { AccountId, PrettyPlayerBundle, PlayerBundle, Account, Team, PlayerBundle__AccountType } from "@ollie-sports/models";
import { getManagedPlayerBundlesForAccountIdFragment } from "../../query-fragments/playerBundle.fragments";
import { fetchPrettyPlayerBundleList, fetchPrettyPlayerBundle } from "../../internal-utils/player-bundle-utils";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";

export async function playerBundle__server__searchInOrg(p: {
  orgId: string;
  searchText: string;
  selfAccountId: string;
}): Promise<{ playerBundle: PlayerBundle; guardianNames: string[]; teamNames: string[] }[]> {
  // SERVER_ONLY_TOGGLE
  const { getAppPgPool, appOllieFirestoreV2: h } = getServerHelpers();

  const hasAccessProm = h.Org.getDoc(p.orgId).then(a => a?.accounts[p.selfAccountId]?.exists);

  const searchResults = await getAppPgPool()
    .query(
      `select player_bundle_item,
      a.id                                                                     as managing_account_id,
      ((a.item ->> 'firstName')::text || ' ' || (a.item ->> 'lastName')::text) as managing_account_name,
      managing_account_type,
      team_item ->> 'name'                                                     as team_name,
      team_item ->> 'id'                                                       as team_id
from (
        select pb.player_bundle_item,
               (jsonb_each(pb.player_bundle_item -> 'managingAccounts')).key             as managing_account_id,
               (jsonb_each(pb.player_bundle_item -> 'managingAccounts')).value -> 'type' as managing_account_type,
               t.item as team_item
        from (
                 select v.player_bundle                                                     as player_bundle_item,
                        v.player_bundle->>'id'                                               as player_bundle_id,
                        (jsonb_each(v.player_bundle -> 'derived' -> 'linkedPlayers')).key   as player_id,
                        (jsonb_each(v.player_bundle -> 'derived' -> 'linkedPlayers')).value as player_info
                 from f_player_bundles_with_name_id_and_self_athlete_account_id($2, null) v
                 where trim(concat(v.first_name,v.last_name)) ilike $1
                   and v.player_bundle -> 'managingAccounts' <> '{}'::jsonb
             ) pb,
             mirror_player p,
             mirror_team t
        where pb.player_id = p.id
          and p.item ->> 'teamId' = t.id
          and t.item ->> 'orgId' = $2
          and t.item ->> 'deletedAtMS' = '0'
          and pb.player_info ->> 'status' = 'active'
    ) c,
    mirror_account a
where c.managing_account_id = a.id;
`,
      [
        `%${p.searchText
          .replace(/[^a-z0-9 ]/gi, "")
          .split(/ +/)
          .filter(Boolean)
          .map(a => a.trim())
          .join("")}%`,
        p.orgId
      ]
    )
    .then(a => {
      return a.rows as {
        player_bundle_item: PlayerBundle;
        managing_account_id: string;
        managing_account_name: string;
        managing_account_type: PlayerBundle__AccountType;
        team_name: string;
        team_id: string;
      }[];
    });

  if (!(await hasAccessProm)) {
    throw new Error("Unable to search requested org!");
  }

  const ret: { playerBundle: PlayerBundle; guardianNames: string[]; teamNames: string[] }[] = [];

  _(searchResults)
    .groupBy(a => a.player_bundle_item.id)
    .map(v => {
      ret.push({
        playerBundle: v[0].player_bundle_item,
        guardianNames: _(v)
          .filter(b => b.managing_account_type === "guardian")
          .uniqBy(b => b.managing_account_id)
          .map(b => b.managing_account_name)
          .value(),
        teamNames: _(v)
          .uniqBy(b => b.team_id)
          .map(b => b.team_name)
          .value()
      });
    })
    .value();

  return ret;

  // SERVER_ONLY_TOGGLE
}

playerBundle__server__searchInOrg.auth = (r: express.Request) => {
  return validateTokenAndEnsureSelfAccountIdMatches(r);
};

// i18n certified - complete
